// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/front-end/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogg-js": () => import("/opt/build/repo/front-end/src/pages/blogg.js" /* webpackChunkName: "component---src-pages-blogg-js" */),
  "component---src-pages-hva-tilbyr-tbnutrition-js": () => import("/opt/build/repo/front-end/src/pages/hva-tilbyr-tbnutrition.js" /* webpackChunkName: "component---src-pages-hva-tilbyr-tbnutrition-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/front-end/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-om-meg-js": () => import("/opt/build/repo/front-end/src/pages/om-meg.js" /* webpackChunkName: "component---src-pages-om-meg-js" */),
  "component---src-pages-slik-fungerer-det-js": () => import("/opt/build/repo/front-end/src/pages/slik-fungerer-det.js" /* webpackChunkName: "component---src-pages-slik-fungerer-det-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/front-end/.cache/data.json")

